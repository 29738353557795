<template>
  <b-modal
    id="topup-manual-modal"
    :title="step === 1 ? 'ฝากมือ' : 'ยืนยันความถูกต้อง'"
    hide-footer
      >
  <ValidationObserver
    ref="creditTransferForm"
    v-slot="{ handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(onSubmit)">
      <template v-if="step === 1">
        <b-dropdown
            id="transferBankAccount" class="w-100 mb-3" variant="outline-dark">
          <template #button-content>
            เลือกบัญชีที่โอน
          </template>
          <template v-if="depositBankAccounts.length > 0">
            <b-dropdown-item
                v-for="bank in depositBankAccounts"
                :key="bank.id"
                block
                @click="selectedBank = bank"
            >
              <b-media vertical-align="center">
                <template #aside>
                  <BankIcon :bank-code="bank.bankCode"/>
                </template>
                {{ bank.bankAccountNumber }}<br>
                {{ bank.bankAccountName}}
              </b-media>
            </b-dropdown-item>
          </template>
          <b-dropdown-item v-else disabled>ไม่มีบัญชี</b-dropdown-item>
        </b-dropdown>
        <b-card v-if="selectedBank.bankCode !== undefined" class="bg-soft-success">
          <b-row align-v="center">
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <BankIcon :bank-code="selectedBank.bankCode"/>
                </template>
                {{ selectedBank.bankAccountNumber }}<br>
                {{ selectedBank.bankAccountName}}
              </b-media>
            </b-col>
            <b-col cols="2">
              <feather
                  type="check-circle"
                  class="icon-lg align-middle icon-dual-success"
              ></feather>
            </b-col>
          </b-row>
        </b-card>
        <b-form-group
            label="เบอร์ยูสเซอร์ *"
            label-for="username"
        >
          <ValidationProvider
              v-slot="{ errors }"
              name="username"
              rules="required|phoneNumber"
          >
            <b-form-input
                id="username"
                v-model="topupForm.username"
                :state="errors[0] ? false : null"
            ></b-form-input>
          </ValidationProvider>
        </b-form-group>
        <p class="text-warning">กรอกวันเวลาตามสลิป</p>
        <b-row>
          <b-col>
            <b-form-group
                label="วัน *"
                label-for="date"
            >
              <ValidationProvider
                  v-slot="{ errors }"
                  name="date"
                  rules="required"
              >
                <b-form-select id="date" v-model="date" :options="dates"
                               :state="errors[0] ? false : null"
                ></b-form-select>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="เดือน *"
                label-for="month"
            >
              <ValidationProvider
                  v-slot="{ errors }"
                  name="month"
                  rules="required"
              >
                <b-form-select id="month" v-model="month" :options="months"
                               :state="errors[0] ? false : null"
                ></b-form-select>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="ปี *"
                label-for="year"
            >
              <ValidationProvider
                  v-slot="{ errors }"
                  name="year"
                  rules="required"
              >
                <b-form-input
                    id="year"
                    v-model="year"
                    :placeholder="currentYear.toString()"
                    :state="errors[0] ? false : null"
                ></b-form-input>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
                label="ชั่วโมง *"
                label-for="hour"
            >
              <ValidationProvider
                  v-slot="{ errors }"
                  name="hour"
                  rules="required"
              >
                <b-form-select id="hour" v-model="hour" :options="hours"
                               :state="errors[0] ? false : null"
                ></b-form-select>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="นาที *"
                label-for="mins"
            >
              <ValidationProvider
                  v-slot="{ errors }"
                  name="mins"
                  rules="required|min:2"
              >
                <b-form-input
                    id="mins"
                    v-model="min"
                    placeholder="00 (ใส่สองตัว)"
                    :state="errors[0] ? false : null"
                ></b-form-input>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
            label="หมายเหตุ *"
            label-for="remark"
        >
          <ValidationProvider
              v-slot="{ errors }"
              name="remark"
              rules="required"
          >
            <b-form-input
                id="remark"
                v-model="topupForm.remark"
                :state="errors[0] ? false : null"
            ></b-form-input>
          </ValidationProvider>
        </b-form-group>
        <ValidationProvider
            v-slot="{ errors }"
            name="amount"
            rules="required"
        >
          <b-form-group
              label="จำนวนเงิน *"
              label-for="amount"
          >
            <b-input-group append="บาท">
              <b-form-input
                  id="amount"
                  v-model="topupForm.amount"
                  type="number"
                  step=".01"
                  :state="errors[0] ? false : null"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </ValidationProvider>
        <b-form-group
            label="หลักฐานการเติมเงิน"
            label-for="proof">
          <b-form-file
              id="proof"
              v-model="topupForm.image"
              accept="image/*"
          >
          </b-form-file>
          <b-form-text class="d-flex justify-content-end">
            <b-link
                v-if="topupForm.image"
                class="text-danger"
                @click="topupForm.image = null"
            >
              {{ $t('buttons.remove')}}
            </b-link>
          </b-form-text>
        </b-form-group>
        <div class="text-center mb-3">
          <img v-if="tempImg" :src="tempImg" class="w-50"/>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <img v-if="tempImg" :src="tempImg" class="w-50"/>
        </div>
        <div class="mt-2">
          ยูสเซอร์เนม:
          <div style="font-size: 18px" class="d-inline ml-2">{{ topupForm.username }}</div>
        </div>
        <div class="mt-1">
          จำนวนเงิน:
          <div style="font-size: 28px" class="text-danger d-inline ml-2 font-weight-bold">{{ topupForm.amount | currency }} บาท</div>
        </div>
        <div class="mt-1">
          เวลาตามสลิป:
          <div class="d-inline ml-2" style="font-size: 18px">
            {{ date.toString().padStart(2, '0') }}/{{ month.toString().padStart(2, '0') }}/{{ year }}
            {{ hour.toString().padStart(2, '0') }}:{{ min }}
          </div>
        </div>
        <div class="mt-1">
          หมายเหตุ: {{ topupForm.remark }}
        </div>

        <hr />
        <div class="mt-1">
          <h4>บัญชีรับโอน</h4>
          <b-card class="bg-soft-success">
            <b-row align-v="center">
              <b-col>
                <b-media vertical-align="center">
                  <template #aside>
                    <BankIcon :bank-code="selectedBank.bankCode"/>
                  </template>
                  {{ selectedBank.bankAccountNumber }}<br>
                  {{ selectedBank.bankAccountName}}
                </b-media>
              </b-col>
              <b-col cols="2">
                <feather
                    type="check-circle"
                    class="icon-lg align-middle icon-dual-success"
                ></feather>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div v-if="+topupForm.amount > 100000">
          <hr />
          <div class="alert alert-danger">
            <h3>คำเตือน</h3>
            คุณกำลังทำรายการฝากมือ ด้วยยอดจำนวน <u class="font-weight-bold font-size-18">{{ (+topupForm.amount).toLocaleString() }}</u> บาท
          </div>
        </div>
      </template>


      <div class="text-right">
        <b-button
          variant="light"
          class="mr-2"
          @click="step === 1 ? $bvModal.hide('topup-manual-modal') : step = 1"
        >
          <span v-if="step === 1">
            {{ $t('buttons.cancel')}}
          </span>
          <span v-else>
            {{ $t('buttons.edit')}}
          </span>
        </b-button>
        <b-overlay
          :show="loading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.confirm')}}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
  </b-modal>
</template>

<script>
import dayjs from 'dayjs'
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'TopUpManualModal',
  props: {
    txn: {
      type: Object,
      default: ()=> {},
    }
  },
  data() {
    return {
      loading: false,
      selectedBank: {},
      step: 1,
      topupForm: {
        amount: '',
        username: '',
        transactionDateTime: '',
        agentBankAccountId: '',
        remark: '',
        image: null
      },
      tempImg: null,
      date: dayjs().date(),
      month: dayjs().month()+1,
      year: dayjs().year()+543,
      hour: dayjs().hour(),
      min: '',
    }
  },
  computed: {
    ...mapState({
      isFetchingBankAccounts: (state) => state.deposit.isFetchingBankAccounts,
    }),
    ...mapGetters(['userInfo', 'depositBankAccounts']),
    dates() {
      const date = new Array(31)
      date.fill(1)
      return date.map((val, key) => { return { value: `${key+val}`, text: `${key+val}`}})
    },
    months() {
      return [
        { value: '1', text: 'มกราคม'},
        { value: '2', text: 'กุมภาพันธ์'},
        { value: '3', text: 'มีนาคม'},
        { value: '4', text: 'เมษายน'},
        { value: '5', text: 'พฤษภาคม'},
        { value: '6', text: 'มิถุนายน'},
        { value: '7', text: 'กรกฎาคม'},
        { value: '8', text: 'สิงหาคม'},
        { value: '9', text: 'กันยายน'},
        { value: '10', text: 'ตุลาคม'},
        { value: '11', text: 'พฤศจิกายน'},
        { value: '12', text: 'ธันวาคม'},
      ]
    },
    currentYear() {
      return (dayjs().year() + 543);
    },
    hours() {
      return [...Array(24).keys()].map((val) => { return { value: val, text: String(val).padStart(2, '0')}})
    },
  },
  watch: {
    selectedBank(val){
      if(val){
        this.topupForm.agentBankAccountId = val.id
      }
    },
    'topupForm.image'(val){
      this.showSelectImage(val)
    }
  },
  created() {
    this.fetchDepositBankAccounts(); 
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      this.selectedBank = {}
    })

    this.$root.$on('bv::modal::hidden', () => {
      this.closeModal()
    })

    this.$root.$on('bv::modal::shown', () => {
      window.document.getElementById('topup-manual-modal').onpaste = (pasteEvent) => {
        if (!pasteEvent.clipboardData.files?.length) {
          return
        }
        this.topupForm.image = pasteEvent.clipboardData.files[0]
      };
    })
  },
  methods: {
    ...mapActions(['fetchDepositBankAccounts', 'addDepositManual', 'notifyError']),
    closeModal() {
      this.$bvModal.hide('topup-manual-modal')
      this.topupForm = {
        amount: '',
        username: '',
        transactionDateTime: '',
        agentBankAccountId: '',
        remark: '',
        image: null,
      }
      this.step = 1
      this.date = dayjs().date()
      this.month = dayjs().month() + 1
      this.year = dayjs().year()+543
      this.hour = dayjs().hour()
      this.min = ''
    },
    async onSubmit() {
      if (this.step === 1) {
        if(!this.selectedBank.id) {
          this.notifyError('กรุณาเลือกบัญชีรับเงิน')
        } else {
          this.step = 2;
        }
      } else {
        this.topupForm.amount = +this.topupForm.amount
        let fullDate = `${(this.year - 543)}`
            fullDate += `-${this.month}`
            fullDate += `-${this.date}`
            fullDate += ` ${this.hour}:${this.min}`
        this.topupForm.transactionDateTime = dayjs(fullDate, 'YYYY-M-D H:mm').format('YYYY-MM-DD HH:mm')
        const isSuccess = await this.addDepositManual({
          agentId: this.userInfo.agentId,
          data: this.topupForm
        })
        if(isSuccess) {
          this.closeModal()
        }
      }
    },
    async showSelectImage(image){
      if (!image){
        this.tempImg = null
        return
      }
      var reader = new FileReader();
      reader.onloadend = () =>  {
        this.tempImg = reader.result;
      }
      reader.readAsDataURL(image);
    }
  },
}
</script>
